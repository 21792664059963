import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BlockContent from "@sanity/block-content-to-react"
import GatsbyImage from "gatsby-image"

const serializers = {
  types: {
    block_image: ({ node }) => {
      return (
        <div>
          <img src={node.asset.url} alt={node.alt}></img>
          <p className="text-sm text-center italic text-gray-500 dark:text-brand-100">
            {node.caption}
          </p>
        </div>
      )
    },
  },
}

const ProjectTemplate = ({ data, pageContext }) => {
  const project = data.sanityProject

  return (
    <Layout>
      <SEO
        title={project.title}
        description={pageContext.rawDescription[0].children[0].text}
      />
      <div>
        <h1 className="text-gray-900 dark:text-brand-500 font-sans text-4xl md:text-6xl md:mb-10 leading-tight">
          {project.title}
        </h1>
        <p className="mb-2 text-base">
          <span className="font-medium text-gray-800 dark:text-gray-50">
            Role:{" "}
          </span>
          {project.roles ? (
            project.roles.map((role, index) => (
              <span>
                {index > 0 ? ", " : ""}
                {role}
              </span>
            ))
          ) : (
            <></>
          )}
        </p>
        <p className="mb-2 text-base">
          <span className="font-medium text-gray-800 dark:text-gray-50">
            Tools & Tech Stack:{" "}
          </span>
          {project.tools ? (
            project.tools.map((tool, index) => (
              <span>
                {index > 0 ? ", " : ""}
                {tool}
              </span>
            ))
          ) : (
            <></>
          )}
        </p>
        <BlockContent
          blocks={project._rawDescription}
          serializers={serializers}
        />
        {project.live_link ? (
          <p>
            Watch it live{" "}
            <a
              href={project.live_link}
              target="_blank"
              rel="noreferrer"
              className="font-medium text-gray-900 dark:text-brand-400 underline"
            >
              here.
            </a>
          </p>
        ) : (
          <></>
        )}
        {project.featured_image ? (
          <div>
            <GatsbyImage
              fluid={project.featured_image.asset.fluid}
              alt={project.featured_image.alt_text}
            ></GatsbyImage>
            <p className="text-sm text-center italic text-gray-500 dark:text-brand-100">
              {project.featured_image.caption}
            </p>
          </div>
        ) : (
          <></>
        )}
        <BlockContent
          blocks={project._rawContent}
          className="project-content mt-8 md:mt-12"
          serializers={serializers}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    sanityProject(id: { eq: $id }) {
      title
      featured_image {
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        alt_text
        caption
      }
      live_link
      roles
      tools
      _rawDescription(resolveReferences: { maxDepth: 5 })
      _rawContent(resolveReferences: { maxDepth: 5 })
    }
  }
`

export default ProjectTemplate
